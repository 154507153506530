import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";

const get_ReportCashFlowStandard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.CASH_FLOW.STANDARD, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ReportCashFlowStandard
}